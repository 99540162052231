import {Collapse, Drawer, message, Switch, Table, Tooltip, Button, Form, Row, Col, Divider, Spin, Popover} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {convertSpeed, getGMTDateTime} from "../../util/CommonUtils";
import {contentBox} from "../../styles";
import {
    ArrowLeftOutlined,
    CalendarOutlined, ClockCircleOutlined,
    DotChartOutlined,
    DownloadOutlined, EnvironmentOutlined, ScanOutlined,
    SettingFilled, UserSwitchOutlined, WifiOutlined
} from "@ant-design/icons";
import permissionStore from "../../stores/permission.store";

import AnalysisExportsStore from "../../stores/analysisExport.store";
import {useForm} from "antd/es/form/Form";
import {Cascader, DatePicker, Select} from "antd/es";
import Radio from "antd/es/radio";
import AnalysisSiteStore from "../../stores/analysisSuper.store";
import {observable, toJS} from "mobx";
import {observer} from "mobx-react-lite";
import AnalysisTripsStore from "../../stores/analysisTrips.store";
import {render} from "react-dom";
import {Spinner} from "react-bootstrap";
import AddressDisplay from "./AddressDisplay";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import WifiActivityTable from "../AnalysisMapTrips/WifiActivityTable";

const { SHOW_CHILD } = Cascader;

const prettyMS = require("pretty-ms");
function TripTable({
    children,
    onTripClick,
    currentlySelectedTrip,
    onDeselectTrip,
    selectedTrips,
    showDrawer,
    onClose,
    onExpand,
    visible,
    onUTCChange,
    downloadModelOpenFunction,
}) {
    const exportStore = useContext(AnalysisExportsStore);
    const permissionStoreContext = useContext(permissionStore);
    const tripsStore = useContext(AnalysisTripsStore);
    const investigationSiteStore = useContext(AnalysisSiteStore)
    const EntityStore = useContext(EntityDisplayStore)
    useEffect(() => {
        investigationSiteStore.getInvestigationData();
    }, []);
    const [AddressState, setAddressState] = useState(null);
    const [calenderUse, setCalenderUse] = React.useState(true);

    const [form] = useForm();

    const columns = [
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (text, record) => {
                return (
                    <p>{investigationSiteStore.getTargetNameById(text, record.type)}</p>
                )
            },
        },
        {
            title: "Start",
            dataIndex: "startAddress",
            key: "startAddress",
            render: (text, record) => {
                const time = getGMTDateTime(record.start, false);
                return(
                    <div>
                        <Col>
                            <p><CalendarOutlined style={{color: '#717171'}}/>   {`${time.split(" ")[0]}`}</p>
                            <p><ClockCircleOutlined style={{color: '#717171'}}/>   {`${time.split(" ")[1]}`}</p>
                            <AddressDisplay lat={record.startLatLng.lat} lng={record.startLatLng.lng} />
                        </Col>
                    </div>
                )
            },
        },
        {
            title: "End",
            dataIndex: "stopped_address",
            key: "stopped_address",
            render: (text, record) => {
                const time = getGMTDateTime(record.end, false);
                return(
                    <div>
                        <Col>
                            <p><CalendarOutlined style={{color: '#717171'}}/>   {`${time.split(" ")[0]}`}</p>
                            <p><ClockCircleOutlined style={{color: '#717171'}}/>   {`${time.split(" ")[1]}`}</p>
                            <AddressDisplay lat={record.endLatLng.lat} lng={record.endLatLng.lng} />
                        </Col>
                    </div>
                )
            },
        },
        {
            title: "Duration",
            dataIndex: "stopped",
            key: "stopped",
            render: (text, record) => {
                const startTime = record.start;
                const endTime = (record && record.end) || 0;
                if (endTime > 0) {
                    const duration = endTime - startTime;
                    return (
                        <div>
                            {`${prettyMS(duration * 1000).split(' ')[0]}`}
                        </div>
                    );
                } else {
                    return `Not processed yet`;
                }
            },
        },
    ];

    const ExpandedColumns = [
        {
            title: "Alias",
            dataIndex: "Alias",
            key: "Alias",
        },
        {
            title: "Entity",
            dataIndex: "EntityName",
            key: "EntityName",
        },
        {
            title: "Capture Type",
            dataIndex: "Type",
            key: "Type",
        },
    ];

    const onFormFinish = (values) => {
        // values.TargetSelection === ['Target'] means all targets
        const copy = values;
        values.TargetSelection.forEach((target) => {
            if (target.length === 1 && target[0] === "Target") {
                copy.TargetSelection = [];
                copy.TargetSelection = investigationSiteStore.getTargetsByInvestigationId(values.Investigation);

            }

        });
        tripsStore.completedForm(copy);
    };

    const onFormFinishFailed = (errorInfo) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };

    const isCurrentlySelectedTrip = (trip) => {
        if (selectedTrips && selectedTrips.length > 0) {
            const isSelected = selectedTrips.filter((x) => x.id === trip.id);

            if (isSelected && isSelected.length > 0) {
                return true;
            } else {
                return false;
            }
        }
    };
    const downLoadmodalOpen = () => {
        if (exportStore.isSet()) {
            downloadModelOpenFunction();
         } else {
            console.log(exportStore.startTime, exportStore.endTime, exportStore.investigation_id, exportStore.target_id);
            message.error('Please select a time range and a target to export points');
         }
    }

    const _showDrawer = () => {
        showDrawer();
    };
    const _onClose = () => {
        onClose();
    };

    const isLastSelectedRecord = (trip) => {
        //
        return currentlySelectedTrip && currentlySelectedTrip.id === trip.id;
    };

    if (!visible) {
        return null
    }

    const exportStyle = {
        float: "right",
        cursor: "pointer",
        fontSize: "20px",
        marginTop: "-5px",
    };

    const highlightAvailableDates = (current) => {
        const style = {};
        if (tripsStore.dateRange.includes(current.startOf('day').format("YYYY-MM-DD").toString())) {
            style.border = '1px solid black';
            style.backgroundColor = "#80808075";
            style.color = "black"
        }
        return (
            <div className="ant-picker-cell ant-picker-cell-in-view" style={style}>
                {current.date()}
            </div>
        );
    }


    if (permissionStoreContext.can("analysis.export")) {
        exportStyle.visibility = "hidden";
    }
    return (
       <Drawer
           zIndex={2}
           title={
               <Row>
                   <Col span={23}>
                       <h4>Trips Analysis
                           <Switch
                               style={{marginLeft: "10px"}}
                               checkedChildren="Local Time"
                               unCheckedChildren="UTC Time"
                               defaultChecked={localStorage.getItem("utcTime") === "false"}
                               onChange={(e) => {
                                   if (e) {
                                       localStorage.setItem("utcTime", "false")
                                   } else {
                                       localStorage.setItem("utcTime", "true")
                                   }
                                   onUTCChange();
                               }}
                           />

                       </h4>
                   </Col>
                   <Col span={1}>
                       <Col>
                           <Tooltip title="export current points">
                               <DownloadOutlined
                                   style={exportStyle}
                                   onClick={() => {
                                       downLoadmodalOpen();
                                       //downloadPoints()
                                   }}
                               />
                           </Tooltip>
                       </Col>
                   </Col>
               </Row>}
           defaultActiveKey={["trips"]}
           style={{
               boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                      0 12.5px 10px rgba(0, 0, 0, 0.06),
                      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                      0 100px 80px rgba(0, 0, 0, 0.12)`,

               marginBottom: "2px",
               marginLeft: "4rem",
               width: '30%',
               maxWidth: '800px'
           }}
           placement="left"
           onClose={_onClose}
           visible={visible}
           mask={false}
           bodyStyle={{background: '#f8f4f4'}}
           headerStyle={{background: '#f8f4f4'}}

       >
           {children}
           <div style={contentBox()}>
           <Form
                    form={form}
                    name="basic"
                    layout="horizontal"
                    initialValues={{Investigation: investigationSiteStore.selectedInvestigation}}
                    onFinish={onFormFinish}
                    onFinishFailed={onFormFinishFailed}
                >
                    <Form.Item name="Investigation" rules={[{required: true, message: "Select and Investigation"}]}>
                        <Select options={investigationSiteStore.InvestigationList}
                            placeholder="Select Investigation"
                            allowClear
                            onChange={(value) => {
                                investigationSiteStore.setInvestigationId(value);
                                tripsStore.investigationId = value;
                                form.setFieldsValue({TargetSelection: []});
                                exportStore.updateInvestigationId(value)
                            }}
                            showSearch={true}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                                filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase()) }
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item name="TargetSelection" rules={[{required: true, message: "Select and Target"}]}>
                        <Cascader
                            multiple
                            maxTagCount="responsive"
                            placeholder="Select Target / Entity"
                            showCheckedStrategy={SHOW_CHILD}
                            options={investigationSiteStore.TargetsForInvestigation}
                            onChange={(val, options) => {
                                console.log(val);
                                let targetsToUse = [];
                                val.forEach((item) => {
                                    if (item[0] === "Target") {
                                        targetsToUse.push(item[1])
                                        exportStore.updateTargetId(item[1])
                                    }
                                })
                                tripsStore.getHightlightedSquares(targetsToUse)
                            }}
                        />
                    </Form.Item>

                    <Row>
                        {calenderUse ?
                        <Form.Item name="Date" initialValue={'86400'}>
                            <Radio.Group defaultValue={'86400'}
                                         size={"small"}
                                         buttonStyle={"solid"}
                            >
                                <Radio.Button value={'43200'}>12 Hours</Radio.Button>
                                <Radio.Button value={'86400'}>24 Hours</Radio.Button>
                                <Radio.Button value={'172800'}>48 Hours</Radio.Button>
                                <Radio.Button value={'259200'}>72 Hours</Radio.Button>

                            </Radio.Group>
                        </Form.Item> :
                            <Form.Item name="datepicker">
                                <DatePicker.RangePicker
                                    dateRender={highlightAvailableDates}
                                />
                            </Form.Item>
                        }


                        <Tooltip title={"Select Time Range"}>
                            <Button type="primary" icon={<CalendarOutlined />} onClick={()=> {
                                setCalenderUse(!calenderUse);
                            }}
                                style={{marginLeft: '5%', borderRadius: '6px'}}
                            />
                        </Tooltip>
                    </Row>


                    <Form.Item >
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            Load Trips
                        </Button>
                        {/*<Button >Exclusive</Button>*/}
                        {/*<Divider type="vertical" />*/}

                    </Form.Item>
                </Form>
            </div>

            <div
                style={contentBox({width: '100%'})}
            >
                <Popover title={"Display Multiple Trips"} content={"Toggling this will display multiple trips on the map"} placement={"topLeft"}>
                    <Button
                        style={{backgroundColor: tripsStore.multipleTarget ? "#4096ff" : "whitesmoke", borderRadius: '10px', color: tripsStore.multipleTarget ? 'white' : 'black'}}
                        icon={<DotChartOutlined />}
                        onClick={() => {
                            tripsStore.setTripsMultiTarget();
                        }}
                    >

                    </Button>
                </Popover>
                <Divider type={"vertical"}  />
                <Popover title={"Display Wifi Activity"} content={"Open a side bar to show Wifi Activity for the trip"} placement={"topLeft"}>
                    <Button style={{backgroundColor: tripsStore.entityDrawVisible ? "#4096ff" : "whitesmoke" ,borderRadius: '10px', color:  tripsStore.entityDrawVisible ? 'white' : 'black'}} icon={<WifiOutlined/>} onClick={() => {
                        tripsStore.setEntityDrawVisible(!tripsStore.entityDrawVisible)
                    }}/>
                </Popover>
                <Divider type={"vertical"}  />
                <Popover title={"Display Knock Knock Scans (Huntsman)"} content={"Show Non capture scans from the huntsman, This can show irrelevant data"} placement={"topLeft"}>
                    <Button
                        icon={<ScanOutlined />}
                        onClick={() => {
                            tripsStore.setUseSniffScans();
                        }}
                        style={{backgroundColor: tripsStore.UseSniffScans ? "#4096ff" : "whitesmoke", borderRadius: '10px', color: tripsStore.UseSniffScans ? 'white' : 'black'}}
                        >

                    </Button>
                </Popover>
                <Divider />
                <Table

                    size="small"
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                tripsStore.selectTheTrip(record);
                                exportStore.updateStartTime(record.start)
                                exportStore.updateEndTime(record.end)
                                return onTripClick(record);
                            },
                            style: (function () {
                                if (tripsStore.sTableSelectedTrip(record)) {
                                    return { backgroundColor: "#e6f4ff"};
                                } else {
                                    return {};
                                }
                            })(),
                        };
                    }}
                    pagination={true}
                    columns={columns}
                    dataSource={tripsStore.tableDisplayTrips()}
                ></Table>
            </div>

       </Drawer>
    );
}

export default observer(TripTable);